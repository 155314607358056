import cx from 'classnames';
import log from 'loglevel';
import { FormattedMessage as T, useIntl } from 'react-intl';
import { commissionStatusEnum, discountType } from '../../cloudEnums';
import SendIcon from '../../images/icons/send.svg';
import CustomersUsingAppSVG from '../../images/illustrations/2023/customers_using_app.svg';
import { logUIEvent } from '../../lib/analytics/analytics';
import { logToSentry } from '../../lib/sentry';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';
import FormattedCurrency from '../Currency/FormattedCurrency';
import m from './messages';
import type { Referral, ReferralCode } from './types';

export const Caption = () => {
  return (
    <h3 className="font-medium uppercase leading-normal tracking-wide text-peach-60">
      <T {...m.caption} />
    </h3>
  );
};

export const Title = () => {
  return (
    <h2 className="text-xl font-bold">
      <T {...m.title} />
    </h2>
  );
};

const Strong = (chunks) => <span className="font-bold">{chunks}</span>;

type DescriptionProps = {
  referralDiscountType: ReferralCode['referralDiscountType'];
  referralDiscountValue: ReferralCode['referralDiscountValue'];
  advocateRewardValue: ReferralCode['advocateRewardValue'];
};

export const Description = ({
  referralDiscountType,
  referralDiscountValue,
  advocateRewardValue,
}: DescriptionProps) => {
  const getReferralDescription = () => {
    switch (referralDiscountType) {
      case discountType.FIXED:
        return (
          <T
            {...m.descriptionDiscount}
            values={{
              amount: <FormattedCurrency value={referralDiscountValue} />,
              strong: Strong,
            }}
          />
        );
      case discountType.PERCENTAGE:
        return (
          <T
            {...m.descriptionDiscount}
            values={{
              amount: `${referralDiscountValue}%`,
              strong: Strong,
            }}
          />
        );
      case discountType.FREE_MONTHS:
        return (
          <T
            {...m.descriptionFreeMonths}
            values={{
              amount: referralDiscountValue,
              strong: Strong,
            }}
          />
        );
      default:
        logToSentry(`Unsupported discount type ${referralDiscountType}`);
        return null;
    }
  };

  return (
    <p>
      {getReferralDescription()}
      {/* advocate reward will be 0 for referral campaigns that
        are not yet migrated to the new internal traffic channels */}
      {advocateRewardValue !== 0 && (
        <>
          {' '}
          <T
            {...m.descriptionAdvocateReward}
            values={{
              amount: <FormattedCurrency value={advocateRewardValue} />,
              strong: Strong,
            }}
          />
        </>
      )}
    </p>
  );
};

type LinkProps = {
  url: ReferralCode['url'];
};

export const Link = ({ url }: LinkProps) => {
  const webShareIsSupported = 'share' in navigator;
  const intl = useIntl();

  const onShare = async () => {
    try {
      await navigator.share({ url });
    } catch (error) {
      log.error(error);
    }
  };

  const shareButtonClasses = cx(
    'bg-peach-60 rounded-md text-sm font-medium text-white flex items-center justify-center',
    webShareIsSupported ? 'px-8' : 'px-2',
    'hover:bg-peach-70',
    'focus:ring-peach-80 focus:outline-none focus:ring-2',
    'active:bg-peach-70 active:ring-peach-80 active:ring-2 active:ring-inset',
  );

  return (
    <div className="mx-auto flex gap-2">
      <div className="flex-1 overflow-hidden rounded-md border border-grey-30">
        <CopyToClipboard
          textToCopy={url}
          onClick={() => {
            logUIEvent({
              action: 'User copied referral link',
              context: 'Referral section',
            });
          }}
        >
          <span className="text-grey-60">
            {url.replace(/^https?:\/\//i, '')}
          </span>
        </CopyToClipboard>
      </div>
      {webShareIsSupported ? (
        <button type="button" onClick={onShare} className={shareButtonClasses}>
          <T {...m.share} />
        </button>
      ) : (
        <a
          href={`mailto:?body=${url}`}
          className={shareButtonClasses}
          title={intl.formatMessage(m.shareViaEmail)}
        >
          <SendIcon className="h-6" aria-hidden="true" />
          <span className="sr-only">
            <T {...m.a11ySendEmailDescription} />
          </span>
        </a>
      )}
    </div>
  );
};

type RewardCardProps = {
  referrals: Array<Referral>;
};

export const RewardCard = ({ referrals }: RewardCardProps) => {
  const paidReferrals = referrals.filter(
    ({ commissionStatus }) => commissionStatus === commissionStatusEnum.PAID,
  );

  const totalReward = paidReferrals.reduce(
    (total, { commissionValue }) => total + commissionValue,
    0,
  );

  return (
    <div className="relative min-w-[250px] max-w-[340px] overflow-hidden rounded-xl bg-blue-80 px-5 pb-2 pt-7 shadow-lg">
      <h2 className="mb-5 text-xl font-bold tracking-wide text-white">
        <T {...m.rewardCardTitle} />
      </h2>
      <div className="text-lg font-medium text-white">
        <FormattedCurrency value={totalReward} />
      </div>
      <p className="text-xs font-medium text-white">
        {paidReferrals.length === 0 ? (
          <T {...m.rewardCardDescriptionInvite} />
        ) : (
          <T
            {...m.rewardCardDescriptionCongrats}
            values={{
              currentCount: paidReferrals.length,
              totalCount: referrals.length,
            }}
          />
        )}
      </p>
      <div className="mt-4 flex flex-row-reverse">
        <CustomersUsingAppSVG className="z-50 w-24" />
      </div>
      <div
        className="absolute -bottom-8 -left-6 h-16 w-44 bg-blue-70"
        style={{ rotate: '15deg' }}
      />
      <div
        className="absolute -bottom-12 -right-6 h-24 w-64 bg-blue-70"
        style={{ rotate: '-15deg' }}
      />
    </div>
  );
};
