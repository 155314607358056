import * as clipboard from 'clipboard-polyfill';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { cn } from '../../lib/classNames';
import m from './messages';

type Props = {
  children: React.ReactNode;
  textToCopy: string;
  onClick: any;
};

const CopyToClipboard = ({ children, textToCopy, onClick }: Props) => {
  const intl = useIntl();
  const [showCopiedToClipboardText, setShowCopiedToClipboardText] =
    useState(false);

  const handleClick = () => {
    onClick();
    clipboard.writeText(textToCopy);
    setShowCopiedToClipboardText(true);
    setTimeout(() => setShowCopiedToClipboardText(false), 2000);
  };

  return (
    <button
      type="button"
      className={cn(
        'flex w-full cursor-pointer justify-between rounded border-0 bg-white font-normal transition-all ease-out',
        {
          'bg-green-20 text-green-80': showCopiedToClipboardText,
        },
      )}
      onClick={handleClick}
    >
      <div className="overflow-hidden text-ellipsis whitespace-nowrap py-4 pl-3 md:pl-4">
        {children}
      </div>
      <span
        className={cn(
          'text-medium ml-auto px-3 py-4 uppercase md:px-6',
          showCopiedToClipboardText ? 'text-green-80' : 'text-blue-60',
        )}
      >
        {showCopiedToClipboardText
          ? intl.formatMessage(m.copied)
          : intl.formatMessage(m.copy)}
      </span>
    </button>
  );
};

export default CopyToClipboard;
