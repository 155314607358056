import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'Referrals';

export default defineMessages({
  caption: {
    id: `${I18N_NAMESPACE}.caption`,
    defaultMessage: 'Bring solar to your friends and family',
  },
  title: {
    id: `${I18N_NAMESPACE}.title`,
    defaultMessage: 'Bring solar to your friends, family and neighborhood',
  },
  descriptionDiscount: {
    id: `${I18N_NAMESPACE}.descriptionDiscount`,
    defaultMessage:
      'Gift your friends and family a <strong>{amount} solar discount</strong>.',
  },
  descriptionFreeMonths: {
    id: `${I18N_NAMESPACE}.descriptionFreeMonths`,
    defaultMessage:
      'Gift your friends and family <strong>{amount, plural, one {one month} other {{amount} months}} free of charge</strong>.',
  },
  descriptionAdvocateReward: {
    id: `${I18N_NAMESPACE}.descriptionAdvocateReward`,
    defaultMessage:
      "When they go solar, you'll receive <strong>{amount}</strong>.",
  },
  rewardCardTitle: {
    id: `${I18N_NAMESPACE}.rewardCardTitle`,
    defaultMessage: 'Earned rewards',
  },
  rewardCardDescriptionInvite: {
    id: `${I18N_NAMESPACE}.rewardCardDescriptionInvite`,
    defaultMessage: 'Invite a friend and earn rewards when they go solar',
  },
  rewardCardDescriptionCongrats: {
    id: `${I18N_NAMESPACE}.rewardCardDescriptionCongrats`,
    defaultMessage:
      'Congrats! {currentCount} out of {totalCount} people have gone solar using your link',
  },
  share: {
    id: `${I18N_NAMESPACE}.share`,
    defaultMessage: 'Share',
  },
  shareViaEmail: {
    id: `${I18N_NAMESPACE}.shareViaEmail`,
    defaultMessage: 'Share via e-mail',
  },
  a11ySendEmailDescription: {
    id: `${I18N_NAMESPACE}.a11ySendEmailDescription`,
    defaultMessage: 'Link to send referral link via e-mail',
  },
});
