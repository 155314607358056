import { PortableText } from '@portabletext/react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { FormEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useSWRMutation from 'swr/mutation';

import { referralSourceEnum } from '@otovo/shared/cloudEnums';
import { validateEmail } from '@otovo/shared/components/Form/InputFieldValidator';
import * as Referrals from '@otovo/shared/components/Referrals/Referrals';
import SanityImage from '@otovo/shared/components/Sanity/SanityImage';
import SanityPortableTextComponents from '@otovo/shared/components/Sanity/SanityPortableTextComponents';
import sharedMessages from '@otovo/shared/components/Translations/messages';
import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import Button from '@otovo/shared/rainbow/Button/Button';
import InputField from '@otovo/shared/rainbow/Form/InputField';
import {
  Cloud$Market,
  Cloud$ReferralSource,
} from '@otovo/shared/types/cloudEnums';
import { Sanity$ReferralSignupSection } from '@otovo/shared/types/sanityTypes';
import getFirstFromArray from '@otovo/shared/utils/getFirstFromArray';
import SectionWrapper from './SectionWrapper';

type SignupRequest = {
  name: string;
  email: string;
  country: Cloud$Market;
  client_source: Cloud$ReferralSource;
};

const sendRequest = async (url: string, { arg }: { arg: SignupRequest }) => {
  const response = await fetch(url, {
    method: 'post',
    body: JSON.stringify(arg),
    headers: { 'content-type': 'application/json' },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

type Props = {
  content: Sanity$ReferralSignupSection;
};
const ReferralSignup = ({ content }: Props) => {
  const { BU_CONFIG } = useGlobalConfig();
  const { query } = useRouter();
  const intl = useIntl();
  const { heading, emailLabel, nameLabel, description, CTAButton, image } =
    content;

  const { utm_source: utmSource } = query;
  const clientSource =
    utmSource === referralSourceEnum.FSM_PAMPHLET
      ? referralSourceEnum.FSM_PAMPHLET
      : referralSourceEnum.ADVOCATE_LANDING_PAGE;

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [link, setLink] = useState('');

  const [validationState, setValidationState] = useState<
    'initial' | 'error' | 'ok'
  >('initial');
  const [errorMessage, setErrorMessage] = useState('');
  const { trigger, isMutating } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_OTOVOAPI_URL}/web/campaigns/generate-referral-code/`,
    sendRequest,
    {
      onSuccess: (data) => {
        setLink(data.url);
      },
      onError: setErrorMessage,
      throwOnError: false,
    },
  );

  useEffect(() => {
    if (validationState !== 'ok' && query.name && query.email) {
      // This should only run on first mount
      try {
        trigger({
          email: getFirstFromArray(query.email),
          name: getFirstFromArray(query.name),
          country: BU_CONFIG.market,
          client_source: clientSource,
        });
        setValidationState('ok');
      } catch (_) {
        setValidationState('error');
      }
    }
  }, [
    query.name,
    query.email,
    clientSource,
    validationState,
    BU_CONFIG,
    trigger,
  ]);

  const handleEmailChange = (e: FormEvent<HTMLInputElement>) => {
    setValidationState('initial');
    setEmail(e.currentTarget.value.trim());
  };

  const handleNameChange = (e: FormEvent<HTMLInputElement>) => {
    setValidationState('initial');
    setName(e.currentTarget.value.trim());
  };

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateEmail(email) && name) {
      trigger({
        email,
        name,
        country: BU_CONFIG.market,
        client_source: clientSource,
      });
    } else {
      setErrorMessage('Invalid');
      setValidationState('error');
    }
    setValidationState('ok');
  };

  if (validationState === 'ok') {
    return (
      <SectionWrapper>
        <div className="col-span-6">
          <h2
            className={cx(
              'text-2xl font-medium md:text-3xl',
              description ? 'mb-4' : 'mb-12',
            )}
          >
            {heading}
          </h2>
          {description && (
            <div className="mb-4">
              <PortableText
                components={SanityPortableTextComponents}
                value={description}
              />
            </div>
          )}
          {link && <Referrals.Link url={link} />}
        </div>
        <div className="col-span-6 col-start-8 hidden w-full md:block">
          <SanityImage image={image} sizes="400px" />
        </div>
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper>
      <div className="col-span-6">
        <h2
          className={cx(
            'text-2xl font-medium md:text-3xl',
            description ? 'mb-4' : 'mb-12',
          )}
        >
          {heading}
        </h2>
        {description && (
          <div className="mb-4">
            <PortableText
              components={SanityPortableTextComponents}
              value={description}
            />
          </div>
        )}
        {link && <p className="text-base">{link}</p>}
        <div className="rounded-md bg-lilac-5 p-8">
          <form onSubmit={submitForm} className="space-y-4">
            <InputField
              id="name"
              label={nameLabel}
              type="text"
              placeholder={intl.formatMessage(sharedMessages.name)}
              onChange={handleNameChange}
              errorMessage={errorMessage}
            />
            <InputField
              id="email"
              label={emailLabel}
              type="email"
              placeholder={intl.formatMessage(sharedMessages.email)}
              onChange={handleEmailChange}
              errorMessage={errorMessage}
            />
            <Button
              type="submit"
              loading={isMutating}
              disabled={!(email && name)}
            >
              {CTAButton}
            </Button>
          </form>
        </div>
      </div>
      <div className="col-span-6 col-start-8 hidden w-full md:block">
        <SanityImage image={image} sizes="400px" />
      </div>
    </SectionWrapper>
  );
};

export default ReferralSignup;
