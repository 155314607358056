import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'CopyToClipboard';

export default defineMessages({
  copy: {
    id: `${I18N_NAMESPACE}.copy`,
    defaultMessage: 'Copy',
  },
  copied: {
    id: `${I18N_NAMESPACE}.copied`,
    defaultMessage: 'Copied!',
  },
});
